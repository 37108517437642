import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { IDropdownFormField } from '../../types/heartcore.types';
import Select from 'react-select';
import CircledArrowIcon from '../svg/circled-arrow-icon';
import NotificationIcon from '../svg/notification-icon';
import { useFieldConditioning } from '../../hooks/use-field-conditioning';
import { getFormatOptionsFromPrevalues } from '../../utils/form/set-format-options-from-prevalues';

const styleProxy = new Proxy({}, {
	get: (_target, _propKey) => () => { /** */ },
});

const Dropdown = (props: IDropdownFormField) => {
	const [field, meta, { setValue }] = useField({name:props.alias, placeholder:''});
	const isErrored = meta.touched && meta.error;
	const [ isFocused, setIsFocused ] = useState(false);
	const [ options, setOptions ] = useState(null);
	const showInput = useFieldConditioning(props);

	const changeHandler = (option) => {
		setValue(option.value);
	};

	useEffect(() => {
		setOptions(getFormatOptionsFromPrevalues(props.preValues));
	}, []);

	if(!showInput) { return <></>; }

	return (
		<div className={`input-wrapper dropdown ${isFocused ? 'input-wrapper--opened' : ''} ${meta.value ? 'input-wrapper--touched' : ''} ${isErrored ? 'input-wrapper--error' : ''}`}>
			<div className='input-inner-wrapper '>
				<label className='input-label' htmlFor={props.alias}>{props.caption}{props.required ? ' *' : ''}</label>
				{options && <Select 
					{...field}
					{...props}
					options={options}
					styles={styleProxy}
					placeholder=''
					onMenuOpen={() => setIsFocused(true)}
					onMenuClose={() => setIsFocused(false)}
					onChange={changeHandler}
					value={{label:props.preValues[field.value] || field.value}}
					className='dropdown__select'
					classNamePrefix='dropdown__select__'
				/>}
				<CircledArrowIcon />
				{isErrored && <NotificationIcon />}
			</div>
			{isErrored && <div className="input-error">{meta.error}</div>}
		</div>
	);
};

export default Dropdown;