import React, { useState } from 'react';
import { useField } from 'formik';
import { IFormField } from '../../types/heartcore.types';
import NotificationIcon from '../svg/notification-icon';
import { useFieldConditioning } from '../../hooks/use-field-conditioning';

const Textarea = (props: IFormField) => {
	const [field, meta] = useField({name:props.alias, placeholder:''});
	const [ isFocused, setIsFocused ] = useState(false);
	const isErrored = meta.touched && meta.error;
	const showInput = useFieldConditioning(props);

	const focusHandler = (value) => {
		setIsFocused(value);
	};

	if(!showInput) { return <></>; }

	return (
		<div className={`input-wrapper input-wrapper--textarea ${isFocused || meta.value ? 'input-wrapper--touched' : ''} ${isErrored ? 'input-wrapper--error' : ''}`}>
			<div className='input-inner-wrapper'>
				<label className='input-label' htmlFor={props.alias}>{props.caption}{props.required ? ' *' : ''}</label>
				<textarea className="textarea" {...field} required={props.required} onFocus={() => focusHandler(true)} onBlur={() => focusHandler(false)} />
				{isErrored && <NotificationIcon />}
			</div>
			{isErrored && <div className="input-error">{meta.error}</div>}
		</div>
	);
};

export default Textarea;