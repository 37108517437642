import React from 'react';

const FullScreenIcon = () => {
	return (
		<svg className='full-screen-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.489 15.477">
			<g id="Group_1172" data-name="Group 1172" transform="translate(.5 .5)" fill="none" stroke="#f5f5f5" strokeLinejoin="round" strokeWidth="1">
				<path id="Path_465" data-name="Path 465" d="M-8200.829-18404.826h3.187v3.174" transform="translate(8212.132 18404.826)"/>
				<path id="Path_468" data-name="Path 468" d="M-8200.829-18401.652h3.187v-3.174" transform="translate(8212.132 18416.129)"/>
				<path id="Path_466" data-name="Path 466" d="M-8197.643-18404.826h-3.186v3.174" transform="translate(8200.828 18404.826)"/>
				<path id="Path_467" data-name="Path 467" d="M-8197.643-18401.652h-3.186v-3.174" transform="translate(8200.828 18416.129)"/>
			</g>
		</svg>
	);
};

export default FullScreenIcon;