import React from 'react';

const CloseIcon = () => {
	return (
		<svg className='close-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.629 21.076">
			<g id="Group_1145" data-name="Group 1145" transform="translate(-10877.919 19059.804)" fill="#f5f5f5">
				<path id="Path_1077" data-name="Path 1077" d="m0 0 2.28-.168 2.707 25.792-2.28.168Z" transform="rotate(-132 1202.372 -11941.771)"/>
				<path id="Path_1100" data-name="Path 1100" d="m0 25.792 2.28.168L4.987.168 2.707 0Z" transform="rotate(-48 -15961.389 -21744.152)"/>
			</g>
		</svg>
	);
};

export default CloseIcon;