import React from 'react';
import { IHero } from '../types/heartcore.types';
import HeartcorePicture from './heartcore-picture';

const Hero = (props: IHero) => {
	const { headline, description, image, video } = props;
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1094, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 957, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 768, width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 584, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 554, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 554, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 554, width: 480, media: '(max-width: 480px)' },
	];

	return (
		<section className='hero'>
			{video 
				?
				<video className='hero__video' autoPlay playsInline loop muted poster={image?._url}>
					<source src={video._url} type="video/mp4" />
				</video>
				:
				<HeartcorePicture image={image} queries={queries} />
			}
			
			<div className='hero__content box-wide'>
				<h1 className='hero__headline'>{headline}</h1>
				<p className='hero__description'>{description}</p>
			</div>
		</section>
	);
};

export default Hero;
