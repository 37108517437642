import gsap from 'gsap/gsap-core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Swipe from 'react-easy-swipe';
import LokaltogContext from '../context/context';
import { IImageGallery } from '../types/heartcore.types';
import { timeout } from '../utils/utils';
import HeartcorePicture from './heartcore-picture';
import ArrowIcon from './svg/arrow-icon';
import CloseIcon from './svg/close-icon';
import DownloadIcon from './svg/download-icon';
import FullScreenIcon from './svg/full-screen-icon';

const IMAGEGALLERYMOBILEMENUBREAKPOINT = 768;

const ImageGallery = (props: IImageGallery) => {
	const { gallery, disableTopMargin, disableBottomMargin } = props;
	const queries = [
		{ alias: 'desktop', height: 158, width: 210, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 400, width: 350, media: '(min-width: 415px)' },
		{ alias: 'mobile', height: 210, width: 170, media: '(max-width: 414px)' },
	];
	const fullScreenQueries = [
		{ alias: 'desktopExtraExtraLarge', height: 1600, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1200, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 1100, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 1100, width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 1222, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 942, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 640, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 730, width: 480, media: '(max-width: 480px)' },
	];
	const context = useContext(LokaltogContext);
	const [ fullScreenMode, setFullScreenMode ] = useState(false);
	const [ activeImageIndex, setActiveImageIndex ] = useState(null);
	const [ moveLeft, setMoveLeft ] = useState(false);
	const [ documentScrollOffset, setDocumentScrollOffset ] = useState(0);
	const imagesRef = useRef({});
	const fullScreenBottomBarRef = useRef(null);

	const enterFullScreenHandler = (index) => {
		setFullScreenMode(true);
		setActiveImageIndex(index);
	};

	const closeFullScreenGalleryHandler = async () => {
		document.body.classList.remove('body--fixed');
		document.documentElement.scrollTo(0, documentScrollOffset);
		gsap.to(fullScreenBottomBarRef.current, {
			yPercent: 100,
			duration: .5,
			ease: 'power1.out',
		});
		gsap.to(imagesRef.current[activeImageIndex], {
			xPercent: -100,
			duration: .5,
			ease: 'power1.out',
		});
		await timeout(500);

		setFullScreenMode(false);
		setActiveImageIndex(null);
	};

	const getNextIndex = (moveToNext: boolean) => {
		const nextIndex = activeImageIndex + (moveToNext ? 1 : -1);
		const newActiveIndex = nextIndex < 0 ? gallery.length - 1 : nextIndex > gallery.length - 1 ? 0 : nextIndex;
		return newActiveIndex;
	};

	const slideImageHandler = (moveToNext: boolean) => {
		setMoveLeft(!moveToNext);

		const newActiveIndex = getNextIndex(moveToNext);

		setActiveImageIndex(newActiveIndex);
	};

	useEffect(() => {
		const initFullScreenMode = async() => {
			if (fullScreenMode) {
				gsap.fromTo(fullScreenBottomBarRef.current, {
					yPercent: 100,
				}, {
					yPercent: 0,
					duration: .5,
					ease: 'power1.out',
				});
				await timeout(300);
				setDocumentScrollOffset(document.documentElement.scrollTop);
				document.body.classList.add('body--fixed');
			}
			return () => gsap.killTweensOf(fullScreenBottomBarRef.current);
		};

		initFullScreenMode();
	}, [fullScreenMode]);

	useEffect(() => {
		if(activeImageIndex !== null) {
			const previousImageIndex = getNextIndex(moveLeft);

			gsap.fromTo(imagesRef.current[previousImageIndex], {
				xPercent: 0,
			}, {
				xPercent: moveLeft ? 100 : -100,
				duration: .5,
				ease: 'power1.out',
			});
			gsap.fromTo(imagesRef.current[activeImageIndex], {
				xPercent: moveLeft ? -100 : 100,
				opacity: 1,
			}, {
				xPercent: 0,
				opacity: 1,
				duration: .5,
				ease: 'power1.out',
			});
		}
	}, [activeImageIndex]);

	return (
		<section className={`image-gallery box-wide ${disableTopMargin ? 'image-gallery--no-top-margin' : ''} ${disableBottomMargin ? 'image-gallery--no-bottom-margin' : ''}`}>
			<div className='image-gallery__inner-wrapper'>
				{gallery?.map((item, index) => 
					<div className='image-gallery__item-wrapper' key={`${index}-${item.caption}`}>
						<div className='image-gallery__image-wrapper'>
							<button  onClick={() => enterFullScreenHandler(index)} aria-label='show image in fullscreen'><HeartcorePicture image={item.image} queries={queries} /></button>
							{context.windowWidth > IMAGEGALLERYMOBILEMENUBREAKPOINT ?
								<div className='image-gallery__image-bottom-bar'>
									<a className='image-gallery__image-bottom-bar__download-button' download href={item.image._url}  target='_blank' rel='noreferrer'>
										<span className='link-with-underline link-with-underline--white'>{context.tr('ImageGallery.Download')}</span>
										<DownloadIcon />
									</a>
									<button  onClick={() => enterFullScreenHandler(index)}  aria-label='show image in fullscreen'><FullScreenIcon /></button>
								</div>
								: null }
						</div>
						<h6 className='image-gallery__image-caption'>{item.caption}</h6>
						{context.windowWidth <= IMAGEGALLERYMOBILEMENUBREAKPOINT ?
							<a className='button image-gallery__mobile-download-button' download href={item.image._url}  target='_blank' rel='noreferrer'>{context.tr('ImageGallery.Download')}</a>
							: null }
					</div>,
				)}
			</div>
			{fullScreenMode ?
				<div className='image-gallery__full-screen-wrapper'>
					<Swipe onSwipeLeft={() => slideImageHandler(true)} onSwipeRight={() => slideImageHandler(false)}>
						<>
							{gallery?.map((item, index) => 
								<div className={`image-gallery__full-screen-image-wrapper ${activeImageIndex !== index ? '' : ''}`}  ref={(element) => imagesRef.current[index] = element} key={`${index}-${item.caption}`}>
									<HeartcorePicture image={item.image} queries={fullScreenQueries} />
								</div>,
							)}
						</>
					</Swipe>
					<button className='image-gallery__full-screen-close-button' onClick={closeFullScreenGalleryHandler}  aria-label='exit fullscreen mode'><CloseIcon /></button>
					<button className='image-gallery__full-screen-nav-button image-gallery__full-screen-nav-button--previous' onClick={() => slideImageHandler(false)}  aria-label='see previous image'><ArrowIcon /></button>
					<button className='image-gallery__full-screen-nav-button image-gallery__full-screen-nav-button--next' onClick={() => slideImageHandler(true)} aria-label='see next image'><ArrowIcon /></button>
					<div ref={fullScreenBottomBarRef} className='image-gallery__full-screen-bottom-bar'>
						<div className='image-gallery__full-screen-bottom-bar__inner-wrapper box-wide'>
							<h6 className='image-gallery__full-screen-image-caption'>{gallery[activeImageIndex].caption}</h6>
							<a className='image-gallery__full-screen-download-button' download href={gallery[activeImageIndex].image._url} target="_blank" rel="noreferrer">
								<span className='link-with-underline link-with-underline--white'>{context.tr('ImageGallery.Download')}</span>
								<DownloadIcon />
							</a>
						</div>
					</div>
				</div>
				: null}
		</section>
	);
};

export default ImageGallery;
