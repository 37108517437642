import React from 'react';
import { Field, useField } from 'formik';
import { IFormField } from '../../types/heartcore.types';
import { useFieldConditioning } from '../../hooks/use-field-conditioning';

const Checkbox = (props: IFormField) => {
	const [field] = useField({name:props.alias, placeholder:''});
	const showInput = useFieldConditioning(props);

	if(!showInput) { return <></>; }

	return (
		<div className='input-wrapper input-wrapper--checkbox'>
			<div className='input-inner-wrapper'>
				<Field className="input--hidden" type='checkbox' {...field} id={props.alias} name={props.alias} checked={field.value} />
				<span className='checkbox-style'></span>
				<label className='checkbox-label' htmlFor={props.alias}>{props.caption}</label>
			</div>
		</div>
	);
};

export default Checkbox;