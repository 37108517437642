import React, { useState } from 'react';
import { Field, useField } from 'formik';
import { ICheckboxList } from '../../types/heartcore.types';
import NotificationIcon from '../svg/notification-icon';
import { useEffect } from 'react';
import { useFieldConditioning } from '../../hooks/use-field-conditioning';
import { getFormatOptionsFromPrevalues } from '../../utils/form/set-format-options-from-prevalues';

const Radio = (props: ICheckboxList) => {
	const [field, meta] = useField({name:props.alias, placeholder:''});
	const isErrored = meta.touched && meta.error;
	const [ options, setOptions ] = useState(null);
	const showInput = useFieldConditioning(props);

	useEffect(() => {
		setOptions(getFormatOptionsFromPrevalues(props.preValues));
	}, []);

	if(!showInput) { return <></>; }

	return (
		<div className={`input-wrapper  input-wrapper--radio-group ${isErrored ? 'input-wrapper--error' : ''}`}>
			<div className='input-inner-wrapper'>
				<fieldset>
					<div className='group-label'>{props.caption}{props.required ? ' *' : ''}</div>
					{options?.map(o => 
						<div className='input-wrapper--radio' key={`radio-${o.label}`}>
							<Field className="input--hidden" type='radio' {...field} id={`${props.alias}-${o.value}`} name={props.alias} value={o.value} />
							<span className='radio-style'></span>
							<label className='radio-label' htmlFor={`${props.alias}-${o.value}`}>{o.label}</label>
						</div>,
					)}
				</fieldset>
				{isErrored && <NotificationIcon />}
			</div>
			{isErrored && <div className="input-error">{meta.error}</div>}
		</div>
	);
};

export default Radio;