import React, { useContext } from 'react';
import LokaltogContext from '../context/context';
import { getCleanUrl } from '../utils/shared-build-runtime-utils';
import { htmlRaw } from '../utils/utils';

//we need to clean up the internal links (remove root node) and keep the external links intact
const RawHtml = ({ content, classes='' }: {content: string, classes?: string }) => {
	const lokaltogContext = useContext(LokaltogContext);

	if(content && !content.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)) {//check if html contains any links
		return <div className={`content-editor ${classes}`} dangerouslySetInnerHTML={htmlRaw(content)}></div>;
	}

	const getParsedHtml = () => {
		const parsed = new DOMParser().parseFromString(content, 'text/html');
		const links = Array.from(parsed.querySelectorAll('a'));
		links.forEach(link => {
			if(link.href.includes(location.origin)) {
				link.href = getCleanUrl(link.href, lokaltogContext.rootNodeUrl);
			}
		});

		return parsed.querySelector('body')?.innerHTML || '';
	};

	return (
		<div className={`content-editor ${classes}`} dangerouslySetInnerHTML={{__html: getParsedHtml()}}></div>
	);
};

export default RawHtml;
