import React, { useState } from 'react';
import { useField } from 'formik';
import { IFormField } from '../../types/heartcore.types';
import NotificationIcon from '../svg/notification-icon';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import 'react-datepicker/dist/react-datepicker.css';
import CircledArrowIcon from '../svg/circled-arrow-icon';
import ArrowIcon from '../svg/arrow-icon';
import { useRef } from 'react';
import ClearIcon from '../svg/clear-icon';
import { useEffect } from 'react';
import { useFieldConditioning } from '../../hooks/use-field-conditioning';

setDefaultLocale(da);

const formatMonth = (date: Date) => {
	const month = date.toLocaleDateString('da-DK', {month: 'long'});
	const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);
	return formattedMonth;
};

const DatePickerInput = (props: IFormField) => {
	const [field, meta, { setValue }] = useField({name:props.alias, placeholder:''});
	const [ isFocused, setIsFocused ] = useState(false);
	const [ showYear, setShowYear ] = useState(false);
	const [ showMonth, setShowMonth ] = useState(false);
	const datePickerRef = useRef(null);
	const isErrored = meta.touched && meta.error;
	const showInput = useFieldConditioning(props);

	const yearPickHandler = (e) => {
		e.preventDefault();
		if (showMonth) {
			setShowMonth(false);
		}
		setShowYear(true);
	};

	const monthPickHandler = (e) => {
		e.preventDefault();
		if(showYear) {
			setShowYear(false);
		}
		setShowMonth(true);
	};

	const valueChangeHandler = (value) => {
		setValue(value?.toDateString());
	};

	const increaseDateHandler = (e, increaseYear, increaseMonth) => {
		e.preventDefault();
		if(showYear) {
			increaseYear();
		} else {
			increaseMonth();
		}
	};

	const decreaseDateHandler = (e, decreaseYear, decreaseMonth) => {
		e.preventDefault();
		if(showYear) {
			decreaseYear();
		} else {
			decreaseMonth();
		}
	};

	useEffect(() => {
		if(showYear) {
			setShowYear(false);
		} else if (showMonth) {
			setShowMonth(false);
		}else {
			datePickerRef.current.setOpen(false);
		}

	}, [field.value]);

	if(!showInput) { return <></>; }

	return (
		<div className={`input-wrapper date-picker-input ${isFocused ? 'input-wrapper--opened' : ''} ${meta.value ? 'input-wrapper--touched' : ''} ${isErrored ? 'input-wrapper--error' : ''}`}>
			<div className='input-inner-wrapper'>
				<label className='input-label' htmlFor={props.alias}>{props.caption}{props.required ? ' *' : ''}</label>
				<DatePicker
					dateFormat="dd / MM - yyyy"
					ref={datePickerRef}
					{...props}
					selected={field.value ? new Date(field.value) : null}
					onChange={valueChangeHandler}
					onFocus={() => setIsFocused(true)}
					onCalendarClose={() => setIsFocused(false)}
					className="input input--date"
					showPopperArrow={false}
					showMonthYearPicker={showMonth}
					showYearPicker={showYear}
					shouldCloseOnSelect={false}
					formatWeekDay={nameOfDay => nameOfDay.substr(0,1).toUpperCase()}
					yearItemNumber={9}
					autoComplete="off"
					isClearable
					renderCustomHeader={({
						date,
						decreaseYear,
						increaseYear,
						decreaseMonth,
						increaseMonth,
					}) => (
						<div className='date-picker-month-navigation'>
							{!showMonth && <button onClick={(e) => decreaseDateHandler(e, decreaseYear, decreaseMonth)}  aria-label='show previous'><ArrowIcon /></button>}
							<div className="react-datepicker__current-month">
								{!showMonth && !showYear && <button onClick={monthPickHandler}>{formatMonth(date)}</button>}
								{!showYear && <button onClick={yearPickHandler}>&nbsp;{date.getFullYear()}</button>}
								{showYear && <span>{date.getFullYear()}</span>}
							</div>
							{!showMonth && 	<button onClick={(e) => increaseDateHandler(e, increaseYear, increaseMonth)}  aria-label='show next'><ArrowIcon /></button>}
						</div>
					)}
				/>
				{!field.value && <CircledArrowIcon />}
				{field.value && <button onClick={() => setValue(null)}  aria-label='clear date'><ClearIcon /></button>}
				{isErrored && <NotificationIcon />}
			</div>
			{isErrored && <div className="input-error">{meta.error}</div>}
		</div>
	);
};

export default DatePickerInput;
