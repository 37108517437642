import { useField, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import LokaltogContext from '../../context/context';
import { useFieldConditioning } from '../../hooks/use-field-conditioning';
import { fetchHeartcoreData } from '../../services/fetch-data';
import { IFileUpload } from '../../types/heartcore.types';
import ClearIcon from '../svg/clear-icon';
import VerificationIcon from '../svg/verification-icon';

const FileUpload = (props: IFileUpload) => { 
	const [field, meta, { setValue, setError } ] = useField({name:props.alias, placeholder:''});
	const { submitCount } = useFormikContext();

	const { fileUploadsFolder } = props;
	const [ selectedFiles, setSelectedFiles ] = useState([]);
	const [ uploadedFiles, setUploadedFiles ] = useState([]);
	const [ uploadingFiles, setUploadingFiles ] = useState(false);
	const [ filenameErrors, setFileNameErrors ] = useState<string[]>([]);
	const context = useContext(LokaltogContext);
	const showInput = useFieldConditioning(props);

	const FILETYPES = {
		'jpg': 'Image',
		'jpeg': 'Image',
		'png': 'Image',
		'gif': 'Image',
		'pdf': 'File',
		'docx': 'File',
		'doc': 'File',
	};

	const INVALIDCHARACTERS = [',', ';', ':', '<', '>', '?', '*', '|', '/', '\\'];

	useEffect(() => {
		if(selectedFiles.length > 0) {
			fileUploadHandler();
		}
	}, [selectedFiles]);

	const fileChangeHandler = (event) => {
		const files = [...event.target.files];
		const nameErrors = files.reduce((acc: string[], curr: {name: string}) => {
			const filename = curr.name;
			if( INVALIDCHARACTERS.some((char) => filename.includes(char))){
				const errorMessage = `Filen: ${filename} må ikke indeholde følgende tegn: , ; : < > ? * | / \\`;
				acc.push(errorMessage);
			}
			return acc;
		}, []);

		setFileNameErrors(nameErrors);
		const validFiles = Array.from(event.target.files).filter((file) => !INVALIDCHARACTERS.some((char) => file.name.includes(char)));

		setSelectedFiles(prev => [...prev, ...validFiles]);
	};

	const removeFileHandler = async (file) => {
		try {
			const data = await fetchHeartcoreData(`https://api.umbraco.io/media/${file.id}`, 
				{ 
					method: 'DELETE',
					headers: {
						'Api-Version': '2.1',
					},
				});

			if (!data.error) {
				const updatedValue = meta.value.filter(v => v !== file.umbracoFileSrc);
				setValue(updatedValue);
				setUploadedFiles(prev => {
					return prev.filter((f) => f.umbracoFileSrc !== file.umbracoFileSrc);
				});
			} else {
				throw new Error(data.error);
			}
		} catch(e) {
			console.error(e);
		}
	};

	const setFormData = (file) => {
		const formData = new FormData();
		const extension = file.name.split('.').reverse()[0].toLowerCase();
		const mediaTypeAlias = FILETYPES[extension] || 'File';
		const umbracoFilename = mediaTypeAlias === 'Image' ? {src: file.name} : file.name;

		const uploadData = {
			'mediaTypeAlias': mediaTypeAlias,
			'name': file.name,
			'parentId': fileUploadsFolder?._id || null,
			'umbracoFile': umbracoFilename,
			'umbracoExtension': extension,
		};

		formData.append('content', JSON.stringify(uploadData));
		formData.append('umbracoFile', file);
		return formData;
	};

	const uploadFile = async (file) => {
		const formData = setFormData(file);
		const data = await fetchHeartcoreData('https://api.umbraco.io/media', 
			{ 
				method: 'POST',
				headers: {
					'Api-Version': '2.1',
				},
				body: formData,
			});

		if (!data.error) {
			const url = `${process.env.GATSBY_UMBRACO_HEARTCORE_BACKOFFICE_URL}${data.mediaTypeAlias === 'Image' ? data.umbracoFile.src: data.umbracoFile}`;
			setUploadedFiles(prev => {
				const fileToUpdate = prev.find((f) => f === file);
				if(fileToUpdate) {
					fileToUpdate.umbracoFileSrc = url;
					fileToUpdate.id = data._id;
					fileToUpdate.loading = false;
				}
				return prev;
			});
		} else {
			throw new Error(data.error);
		}
	};

	const fileUploadHandler = async () => {
		setUploadingFiles(true);
		selectedFiles.forEach(file => file.loading = true);
		try {
			setUploadedFiles(prev => [...prev, ...selectedFiles]);
			await Promise.allSettled(selectedFiles.map((file) => uploadFile(file)));
			setSelectedFiles([]);
			setUploadingFiles(false);
		} catch(e) {
			console.error(e);
			setUploadingFiles(false);
			setError(context.tr('FileUpload.FailedToUpload'));
		}
	};

	useEffect(() => {
		if(!uploadingFiles) {
			const urls = uploadedFiles.map(file => file.umbracoFileSrc);
			setValue(urls);
		}
	}, [uploadingFiles]);

	if(!showInput) { return <></>; }

	return ( 
		<div className={`input-wrapper file-upload  ${meta.error && meta.touched ? 'input-wrapper--error' : ''}`}>
			<label className='input-label' htmlFor={props.alias}>
				<div className='file-upload__caption'>{props.caption}{props.required ? ' *' : ''}</div>
				<div><div className='link file-upload-trigger'>{context.tr('FileUpload.ChooseFile')}</div></div>
			</label>
			<input
				className="input--hidden"
				type='file'
				multiple
				id={field.name}
				name={field.name}
				required={props.required}
				onChange={fileChangeHandler}
			/>
			{uploadedFiles.length > 0 && 
				uploadedFiles.map((file, index) => (
					<div key={index}>
						<div className='file-upload-trigger'>
							<a className='link' download href={`${file.umbracoFileSrc}`} target='_blank' rel='noreferrer'>{file?.name}</a>
							{file.loading && <div className='spinner'></div>}
							{Boolean(!file.loading && submitCount === 0) && 
								<>
									<button onClick={() => removeFileHandler(file)} type='button' aria-label='delete file'>
										<ClearIcon />
									</button>
									<VerificationIcon />
								</>}
						</div>
					</div>
				))}
			{filenameErrors.length > 0 && filenameErrors.map((err, idx) => <div key={`err-${idx}`} className='input-error'>{err}</div>)}
			{meta.error && meta.touched ? (
				<div className="input-error">{meta.error}</div>
			) : null}
		</div>
	); 
};

export default FileUpload; 
