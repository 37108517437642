import React from 'react';

const Train = ({svgRef, section1MarkerRef, section2MarkerRef, section3MarkerRef, section4MarkerRef}) => {
	return (
		<svg ref={svgRef} className='train-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1119.1 195.64">
			<g id="Layer_2" data-name="Layer 2">
				<g id="Train">
					<path d="M80.26 154.06h100.58l6.79 18.73h127.69v-119a36.18 36.18 0 0 0-36.18-36.18H0v155.18h73.48ZM971.54 17.29H331a7.35 7.35 0 0 0-7.35 7.35V137.5a7.36 7.36 0 0 0 7.35 7.36h313.52V51.28a7.39 7.39 0 0 1 7.39-7.39h55.59a7.39 7.39 0 0 1 7.39 7.39v93.58h404.21l-9.91-24.61c-22.62-56.17-77.1-102.96-137.65-102.96Z" fill="#ddd"/>
					<path d="M971.54 17.29h-215l70.16 127.57h292.4l-9.91-24.61c-22.62-56.17-77.1-102.96-137.65-102.96Z" fill="#e41928"/>
					<path fill="#aaa" d="m426.18 0-10 17.29h129.03L535.21 0H426.18zM772.81 0l-10 17.29h129.03L881.84 0H772.81zM714.89 151.27a7.39 7.39 0 0 1-7.39 7.39h-55.59a7.39 7.39 0 0 1-7.39-7.39v-6.41H324.2v27.61h31L368.62 152h95.12l13.39 20.46h400.11L890.63 152h95.12l13.39 20.46h92.35a27.62 27.62 0 0 0 27.61-27.61H714.89Z"/>
					<path d="M1012.22 49.65a4.21 4.21 0 0 0-4.43 4.19v46A8.16 8.16 0 0 0 1016 108h58a5.83 5.83 0 0 0 5.27-8.32c-7.4-15.55-27.27-47.78-67.05-50.03Z" fill="#b7cce2"/>
					<path d="M1007.92 101.24a8.14 8.14 0 0 0 8 6.8h58a5.83 5.83 0 0 0 5.75-6.8Z" fill="#01285f"/>
					<path d="M331 17.29a7.35 7.35 0 0 0-7.35 7.35v8.42h708.42c-18.69-10-39.25-15.77-60.57-15.77Z" fill="#00275e"/>
					<circle cx="902.87" cy="173.72" r="17.24" fill="#00275e"/>
					<circle cx="970.38" cy="173.09" r="17.24" transform="rotate(-84.34 970.432 173.057)" fill="#00275e"/>
					<circle cx="382.43" cy="174.03" r="17.24" transform="matrix(.99 -.16 .16 .99 -22.94 63.51)" fill="#00275e"/>
					<circle cx="449.94" cy="173.4" r="17.24" fill="#00275e"/>
					<path fill="#aaa" d="m334.04 1-3 16.29h21.36L349.4 1h-15.36z"/>
					<g id="LOGO">
						<g id="Group_1" data-name="Group 1" fill="#00275e">
							<path d="M1085.52 121.56H1072v1h13.6a2.3 2.3 0 0 1 2.17 1.66 2.74 2.74 0 0 1 .09.7v10.19a2.33 2.33 0 0 1-2.35 2.36H1082v.94h3.58a3 3 0 0 0 .52 0 3.24 3.24 0 0 0 2.75-3.19v-10.43a3.08 3.08 0 0 0-.5-1.72 3.22 3.22 0 0 0-2.83-1.51ZM1082.18 128.35z"/>
							<path d="M1083.55 127.47v.55a1.55 1.55 0 0 0-.83-.56 2.36 2.36 0 0 0-1.09 0 2 2 0 0 0-1.67 1.5 2.86 2.86 0 0 0 0 1.69 2 2 0 0 0 1.22 1.32 2.38 2.38 0 0 0 1.06.15 1.68 1.68 0 0 0 1.3-.7 2.41 2.41 0 0 1-.11 1.22 1.11 1.11 0 0 1-.76.64 3 3 0 0 1-.43 0 1.08 1.08 0 0 1-.18 0v1a4.17 4.17 0 0 0 .5 0 2.15 2.15 0 0 0 1.84-1.18 2.39 2.39 0 0 0 .26-1.08V127.49Zm-.13 2.71a1.26 1.26 0 0 1-1.4.88h-.08a1.3 1.3 0 0 1-1.09-1.47 1.8 1.8 0 0 1 .06-.32 1.24 1.24 0 0 1 1.27-.91 1.24 1.24 0 0 1 1.29 1.13 1.51 1.51 0 0 1-.05.69ZM1067.14 127.46c-.06.19 0 .38-.08.58v-.06a1.56 1.56 0 0 0-.9-.58 2.36 2.36 0 0 0-1.12 0 2 2 0 0 0-1.6 1.54 3 3 0 0 0 0 1.61 2 2 0 0 0 1 1.22 2.32 2.32 0 0 0 1.57.23 1.53 1.53 0 0 0 1.06-.66l.06.61h1v-4.46Zm-.13 2.65a1.25 1.25 0 0 1-1.25.94h-.22a1.36 1.36 0 0 1 .11-2.7 1.37 1.37 0 0 1 .35 0 1.31 1.31 0 0 1 1.06 1.51 1.09 1.09 0 0 1-.06.25Z"/>
							<path d="m1061.48 129.68-.11-.13.16-.19 1.58-1.76s.08-.07.07-.15H1062a.33.33 0 0 0-.27.12c-.42.52-.85 1-1.27 1.53l-.1.11v-3.65h-1.08v6.32h1.08v-2l.1.11 1.54 1.78a.23.23 0 0 0 .22.1h1.18c0-.09 0-.13-.08-.17ZM1057.51 129.7zM1058 128.05a2.26 2.26 0 0 0-1.7-.69H1056.01a2.11 2.11 0 0 0-1.75 1.17 2.39 2.39 0 0 0-.23 1.64 2.1 2.1 0 0 0 1.51 1.72 2.58 2.58 0 0 0 1.69 0 2.07 2.07 0 0 0 1-.82 2.44 2.44 0 0 0 .36-1.64 2.15 2.15 0 0 0-.59-1.38Zm-.57 2.19a1.16 1.16 0 0 1-1.3.76 1.14 1.14 0 0 1-1-.82 1.49 1.49 0 0 1 .11-1.22 1 1 0 0 1 .25-.3 1.17 1.17 0 0 1 1.66.09 1.45 1.45 0 0 1 .35.95 1.4 1.4 0 0 1-.09.54ZM1076.85 127.36h-.23a2.11 2.11 0 0 0-1.73 1.1 2.49 2.49 0 0 0-.11 2.21 2.06 2.06 0 0 0 1.34 1.22 2.65 2.65 0 0 0 1.6 0 2.12 2.12 0 0 0 1.31-1.19 2.27 2.27 0 0 0 .2-.89 2.27 2.27 0 0 0-2.38-2.46Zm1 3.16a1.22 1.22 0 0 1-2.19-.6v-.29a1.46 1.46 0 0 1 .23-.73.79.79 0 0 1 .18-.22 1.17 1.17 0 0 1 1.67.06 1.47 1.47 0 0 1 .15 1.78ZM1051 130.82v-5.19h-1.11v6.32h3.61a5.67 5.67 0 0 0 0-1.09ZM1073.12 127.46v-1.87H1072v1.87h-.9v1h.91v2.63a8.42 8.42 0 0 0 0 .87h1.07v-3.36a1.48 1.48 0 0 1 0-.2h1.37a4.92 4.92 0 0 0 0-.94ZM1069.13 125.59h1.07v6.32h-1.07z"/>
						</g>
					</g>
					<path d="M1027.45 123.73v2c0 .37.17.53.53.53h3.31v10a.46.46 0 0 0 .37.53h2.8a.45.45 0 0 0 .53-.37.43.43 0 0 0 0-.16V123.2h-7c-.37 0-.54.16-.54.53Z" fill="#00275e"/>
					<circle cx="1037.81" cy="124.82" r="1.91" fill="#00275e"/>
					<path d="M1008.15 123.2h-5.54c-.35 0-.52.16-.52.53v12.55c0 .38.17.53.52.53h5.54c3.48 0 5.34-.79 5.34-6.8s-1.86-6.81-5.34-6.81Zm-.2 10.64h-2.16v-7.67h2.21c1.43 0 1.75.63 1.75 3.84s-.37 3.83-1.75 3.83ZM1020.93 123c-4.38 0-5.88 1.09-5.88 7s1.5 7 5.88 7 5.87-1.09 5.87-7-1.5-7-5.87-7Zm0 11c-1.87 0-2.12-.37-2.12-4s.26-4 2.12-4 2.11.38 2.11 4-.24 4-2.11 4Z" fill="#00275e"/>
					<circle cx="162.7" cy="178.4" r="17.24" transform="rotate(-67.5 162.698 178.396)" fill="#00275e"/>
					<circle cx="95.19" cy="177.77" r="17.24" transform="rotate(-4.07 95.136 177.526)" fill="#00275e"/>
					<path fill="#aaa" d="m126.3 4.37-10 17.29h129.02l-10-17.29H126.3zM80.26 154.06h100.58l6.79 18.73h127.69v-27.84H0v27.84h73.48l6.78-18.73z"/>
					<path d="M279.14 17.61H0v15.45h308.79a36.13 36.13 0 0 0-29.65-15.45ZM0 54.38h303.37v83.93H0z" fill="#00275e"/>
					<path fill="#aaa" d="M315.32 64.13h8.37v70.66h-8.37z"/>
					<rect x="198.92" y="40.15" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M198.92 113.13a7.39 7.39 0 0 0 7.39 7.39h55.59a7.39 7.39 0 0 0 7.39-7.39v-.73h-70.37Z" fill="#01285f"/>
					<path d="M198.92 83.21V102l70.37-40.63v-14a7.32 7.32 0 0 0-1.2-4Z" fill="#cfe5f9"/>
					<rect x="108.19" y="40.15" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M108.58 113.13a7.38 7.38 0 0 0 7.38 7.39h55.6a7.39 7.39 0 0 0 7.39-7.39v-.73h-70.37Z" fill="#01285f"/>
					<path d="M108.58 83.21V102L179 61.33v-14a7.4 7.4 0 0 0-1.2-4Z" fill="#cfe5f9"/>
					<rect x="17.46" y="40.15" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M17.46 113.13a7.39 7.39 0 0 0 7.39 7.39h55.59a7.39 7.39 0 0 0 7.39-7.39v-.73H17.46Z" fill="#01285f"/>
					<path d="M17.46 83.21V102l70.37-40.67v-14a7.32 7.32 0 0 0-1.2-4Z" fill="#cfe5f9"/>
					<path fill="#00275e" d="M341.72 46.99v83.94h471.95l-46.16-83.94H341.72z"/>
					<rect x="371.49" y="43.89" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M371.49 116.87a7.39 7.39 0 0 0 7.39 7.39h55.59a7.39 7.39 0 0 0 7.39-7.39v-.74h-70.37Z" fill="#01285f"/>
					<path d="M371.49 51v14l70.37 40.63V87l-69.17-40a7.32 7.32 0 0 0-1.2 4Z" fill="#cfe5f9"/>
					<rect x="462.22" y="43.89" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M461.84 116.87a7.39 7.39 0 0 0 7.39 7.39h55.59a7.39 7.39 0 0 0 7.39-7.39v-.74h-70.37Z" fill="#01285f"/>
					<path d="M461.84 51v14l70.37 40.63V87L463 47a7.39 7.39 0 0 0-1.16 4Z" fill="#cfe5f9"/>
					<rect x="552.95" y="43.89" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<rect x="644.52" y="43.89" width="70.37" height="114.77" rx="7.39" fill="#00275e"/>
					<rect x="650.63" y="50.79" width="27.27" height="100.97" rx="7.39" fill="#b7cce2"/>
					<rect x="682.81" y="50.79" width="27.27" height="100.97" rx="7.39" fill="#b7cce2"/>
					<path d="M553 116.87a7.39 7.39 0 0 0 7.39 7.39h55.59a7.39 7.39 0 0 0 7.39-7.39v-.74H553Z" fill="#01285f"/>
					<path d="M553 51v14l70.37 40.63V87l-69.22-40a7.4 7.4 0 0 0-1.15 4Z" fill="#cfe5f9"/>
					<rect x="735.17" y="43.89" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M735.17 116.87a7.39 7.39 0 0 0 7.39 7.39h55.59a7.39 7.39 0 0 0 7.39-7.39v-.74h-70.37Z" fill="#01285f"/>
					<path d="M735.17 54v14l70.37 40.63V90l-69.17-40a7.32 7.32 0 0 0-1.2 4Z" fill="#cfe5f9"/>
					<rect x="825.51" y="43.89" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M825.51 116.87a7.39 7.39 0 0 0 7.39 7.39h55.6a7.38 7.38 0 0 0 7.38-7.39v-.74h-70.37Z" fill="#01285f"/>
					<path d="M825.51 54v14l70.37 40.63V90l-69.17-40a7.4 7.4 0 0 0-1.2 4Z" fill="#cfe5f9"/>
					<rect x="916.63" y="43.89" width="70.37" height="80.37" rx="7.39" fill="#b7cce2"/>
					<path d="M916.63 116.87a7.39 7.39 0 0 0 7.39 7.39h55.59a7.39 7.39 0 0 0 7.39-7.39v-.74h-70.37Z" fill="#01285f"/>
					<path d="M916.63 54v14L987 108.69V90l-69.17-40a7.32 7.32 0 0 0-1.2 4Z" fill="#cfe5f9"/>
				</g>
				<g id="Layer_3" data-name="Layer 3">
					<circle ref={section1MarkerRef} cx="1041.77" cy="39.47" r=".1" fill='none'/>
					<circle ref={section2MarkerRef} cx="744.11" cy="125.81" r=".1" fill='none'/>
					<circle ref={section3MarkerRef} cx="428.37" cy="2.18" r=".1" fill='none'/>
					<circle ref={section4MarkerRef} cx="211.32" cy="118.33" r=".1" fill='none'/>
				</g>
			</g>
		</svg>
	);
};

export default Train;