import React from 'react';

const VerificationIcon = () => {
	return (
		<svg className='verification-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.178 37.178">
			<g>
				<path d="M18.589 0A18.589 18.589 0 1 1 0 18.589 18.589 18.589 0 0 1 18.589 0Z" fill="#225f41"/>
				<path d="m16.009 27.601-7.747-7.278 2.582-2.431 5.165 4.855L27.63 11.823l2.582 2.427-11.621 10.924Z" fill="#fff"/>
			</g>
		</svg>
	);
};

export default VerificationIcon;